import React, { useState, useEffect } from 'react'
import Router from 'next/router'
import ReactGA from 'react-ga'

import { useStore } from '../../store/hooks'

interface GoogleAnalyticsContextValue {
  // trackEvent: (event: ReactGA.EventArgs) => void
  trackEvent: (...args) => void
}

const TRACKING_ID = 'UA-19186916-2'
const GoogleAnalyticsContext = React.createContext<GoogleAnalyticsContextValue>(undefined)

function GoogleAnalyticsProvider(props) {
  const userId = useStore(store => store.auth.user?.id)

  const [analytics, setAnalytics] = useState({
    isInitialized: false,
    hasUser: false,
    trackers: [],
  })

  const handleRouteChange = url => {
    ReactGA.set({ page: url, non_interaction: true }, analytics.trackers)
    ReactGA.pageview(url, analytics.trackers)
  }

  const trackEvent = (...args) => {
    if (!analytics.isInitialized) return
    ReactGA.plugin.execute.apply(this, args)
  }

  /* useEffect here so it is only run on the client. */
  useEffect(() => {
    const { isInitialized, hasUser, trackers } = analytics

    if (!isInitialized) {
      ReactGA.initialize(TRACKING_ID, { gaOptions: { userId } })
      ReactGA.plugin.require('ec')

      Router.events.on('routeChangeComplete', handleRouteChange)

      setAnalytics(state => ({
        ...state,
        isInitialized: true,
        hasUser: Boolean(userId),
      }))
    } else if (isInitialized && !hasUser) {
      ReactGA.set({ userId }, trackers)

      setAnalytics(state => ({
        ...state,
        hasUser: Boolean(userId),
      }))
    }

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [userId])

  return <GoogleAnalyticsContext.Provider {...props} value={{ trackEvent }} />
}

const useGoogleAnalytics = () => React.useContext(GoogleAnalyticsContext)

export { GoogleAnalyticsProvider, useGoogleAnalytics }
